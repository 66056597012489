import api from "../../../api/api";

const getList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/location-manager").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/location-manager/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getByParentId = (parentId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/location-manager/parent/"+parentId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/location-manager",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/location-manager",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const remove = (id) => {
    return new Promise((resolve, reject)=>{
        api.delete("/location-manager/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}





const locactionManagerServices = {
    getList,
    getById,
    getByParentId,
    save,
    updateInline,
    remove
}
export default locactionManagerServices;