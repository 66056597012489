import React, { useEffect, useState } from 'react'
import { useAlert } from '../../../context/AlertContext'
import visitorServices from '../visitorServices';
import VisitorForm from './VisitorForm';
import SearchByPhone from './SearchByPhone';
import GatepassImageCapture from './GatepassImageCapture';


function SelectVisitor({setVisitor,next}) {

    const {AppAlert} = useAlert();
    const [visitorIdInput,setVisitorIdInput] = useState("");
    const [visitorDetails,setVisitorDetails] = useState(null);
    
    const getVisitorDetails = () =>{
        if(visitorIdInput != ""){
            visitorServices.getById(visitorIdInput).then(res=>{
                setVisitorDetails(res.data);
                setVisitor(res.data)
            }).catch(error=>{
                setVisitorDetails(null);
                AppAlert(error.message);
            })
        }
    }

    useEffect(()=>{
        getVisitorDetails();
    },[visitorIdInput])

    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" placeholder="" value={visitorIdInput} onChange={(e)=>setVisitorIdInput(e.target.value)} />
                    <label >Search / Scan Id</label>
                </div>
            </div>
            <div className='col-md-6 p-2'>
                <div class="d-flex justify-content-start align-items-center">
                    <span>Or</span>
                    <SearchByPhone setVisitorIdInput={setVisitorIdInput} />
                    <span>Or</span>
                    <VisitorForm setVisitorIdInput={setVisitorIdInput} />
                </div>
            </div>
            {visitorDetails &&
                <div className='col-md-3'>
                    <div className='card'>
                        {/* <img src={visitorDetails.photo === null ? "/images/blank-profile.png" : visitorDetails.photo} class="card-img-top" alt="..." /> */}
                        <GatepassImageCapture visitorDetails={visitorDetails} getVisitorDetails={getVisitorDetails} />
                        <div className='card-body'>
                            <h5 class="card-title">{visitorDetails.visitorName}</h5>
                            <p class="card-text">{visitorDetails.address}</p>
                        </div>
                        <div className='card-footer'>
                            <button className='btn btn-primary float-end' onClick={next} >Next</button>
                        </div>
                    </div>
                </div>
            }
            
        </div>
    )
}

export default SelectVisitor


/*

<div className="input-group mb-3">
                    <span className="input-group-text">Phone Number</span>
                    <input type="text" className="form-control" placeholder='' />
                    <button className="btn btn-outline-secondary" type="button"> Search</button>
                </div>

*/