import api from "../api/api";

const getStaffDDList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/gatepass/staff/dd").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getBusinessInfo = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/business").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const hookServices = {
    getStaffDDList,
    getBusinessInfo
}
export default hookServices;