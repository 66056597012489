import React, { useState, useEffect } from 'react'
import { AlertProvider } from './global-gatepass/context/AlertContext';
import { useAuth } from './global-gatepass/context/AuthContext'
import { Route, Routes } from 'react-router'

import AdminRoutes from './global-gatepass/routes/AdminRoutes'
import AuthRoutes from './global-gatepass/routes/AuthRoutes'

import LogoutPage from './global-gatepass/app/auth/pages/LogoutPage';
import RechargePage from './global-gatepass/app/admin/business/pages/RechargePage';
import SuspendPage from './global-gatepass/app/admin/business/pages/SuspendPage';

import Error404Page from './global-gatepass/app/errorPages/Error404Page';
import Error401Page from './global-gatepass/app/errorPages/Error401Page';
import ChangePasswordPage from './global-gatepass/app/auth/pages/ChangePasswordPage';


function App() {
    const {isLoggedIn,authUser} = useAuth();
    //console.log("authUSerrole", authUser)
    sessionStorage.removeItem("businessInfo");

    // Disable Right Click Start
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])
    // Disable Right Click End
    
    return (
        <>
        <AlertProvider>
            {isLoggedIn ? 
                <>
                    { authUser.role <= 2 &&
                        <AdminRoutes/>
                    }
                    
                    <Routes>
                        {/*Logout */}
                        <Route path="/admin/recharge-page" element={<RechargePage />}></Route>
                        <Route path="/admin/suspend-page" element={<SuspendPage />}></Route>
                        <Route path="/auth/logout" element={<LogoutPage/>}></Route>
                        <Route path="/auth/change-password" element={<ChangePasswordPage />}></Route>
                        <Route path="/404" element={<Error404Page/>}></Route>
                        <Route path="/401" element={<Error401Page/>}></Route>
                    </Routes> 
                </>
                :
                <AuthRoutes/>
            }
        </AlertProvider>
        </>
    )
}
export default App