import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../FormikControl/FormikControl';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import moment from 'moment';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



function DateSelect( { filter, setFilter}) {

    const [dialogStatus,setDialogStatus]  = useState(false);

    const [searchingInProgress, setSearchingStatus] = useState(false);
    const initialValues = {
        startDate:moment().format(moment.HTML5_FMT.DATE),
        endDate:moment().format(moment.HTML5_FMT.DATE),
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log("Searching ", values)
        setFilter(values);
        setDialogStatus(false);
    }

    const validationRule = Yup.object({
        startDate: Yup.string().required("Required"),
        endDate: Yup.string().required("Required")
    });

    useEffect(()=>{
        setFilter(initialValues);
    },[])

    return (
        <>
            <button className='btn btn-outline-primary' onClick={()=>setDialogStatus(true)} >
                {filter && filter.startDate === moment().format("YYYY-MM-DD") && filter.endDate == moment().format("YYYY-MM-DD") && "Today"}
                {filter && filter.startDate === moment().add(-1, 'days').format("YYYY-MM-DD") && filter.endDate == moment().add(-1, 'days').format("YYYY-MM-DD") && "Yesterday"}
                {filter && filter.startDate !== filter.endDate  &&  
                    <> 
                        {moment(filter.startDate).format("ll")} to {moment(filter.endDate).format("ll")} 
                    </>
                }
            </button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>{setDialogStatus(false)}}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationRule}
                        onSubmit={onSubmit}
                        enableReinitialize validateOnMount
                    >
                        {
                            (formik) => {
                                //console.log(formik.values);
                                return (
                                    <Form>
                                        <div className='row mb-3'>
                                            <div className='col-md-12'>
                                                <button className='btn btn-primary me-2'
                                                    onClick={()=>{
                                                        formik.setFieldValue("startDate", moment().format("YYYY-MM-DD"));
                                                        formik.setFieldValue("endDate", moment().format("YYYY-MM-DD"));
                                                    }}
                                                >Today</button>
                                                <button className='btn btn-primary me-2'
                                                    onClick={()=>{
                                                        formik.setFieldValue("startDate", moment().add(-1,"days").format("YYYY-MM-DD"));
                                                        formik.setFieldValue("endDate", moment().add(-1,"days").format("YYYY-MM-DD"));
                                                    }}
                                                >Yesterday</button>
                                                <button className='btn btn-primary me-2'
                                                    onClick={()=>{
                                                        formik.setFieldValue("startDate", moment().weekday(1).format("YYYY-MM-DD"));
                                                        formik.setFieldValue("endDate", moment().format("YYYY-MM-DD"));
                                                    }}
                                                >This Week</button>

                                                <button className='btn btn-primary me-2'
                                                    onClick={()=>{
                                                        formik.setFieldValue("startDate", moment().subtract(1,'weeks').startOf('weeks').format("YYYY-MM-DD"));
                                                        formik.setFieldValue("endDate", moment().subtract(1,'weeks').endOf('weeks').format("YYYY-MM-DD"));
                                                    }}
                                                >Last Week</button>

                                                <button className='btn btn-primary me-2'
                                                    onClick={()=>{
                                                        formik.setFieldValue("startDate", moment().add(-7,"days").format("YYYY-MM-DD"));
                                                        formik.setFieldValue("endDate", moment().format("YYYY-MM-DD"));
                                                    }}
                                                >Last 7 Days</button>

                                                <button className='btn btn-primary me-2'
                                                    onClick={()=>{
                                                        formik.setFieldValue("startDate", moment().startOf('month').format("YYYY-MM-DD"));
                                                        formik.setFieldValue("endDate", moment().format("YYYY-MM-DD"));
                                                    }}
                                                >This Month</button>

                                                <button className='btn btn-primary me-2'
                                                    onClick={()=>{
                                                        formik.setFieldValue("startDate", moment().subtract(1,'months').startOf('month').format("YYYY-MM-DD"));
                                                        formik.setFieldValue("endDate", moment().subtract(1,'months').endOf('month').format("YYYY-MM-DD"));
                                                    }}
                                                >Last Month</button>
                                            </div>
                                        </div>
                                        <div className="row p-2">
                                            <FormikControl control="date" formik={formik} type="date" 
                                                label="Start Date" name="startDate"
                                                min={moment().subtract(60, 'day').format(moment.HTML5_FMT.DATE)}
                                                //max={moment().subtract(0, 'day').format(moment.HTML5_FMT.DATE)}    
                                                className="col-md-4 col-xs-12 me-2" 
                                            />

                                            <FormikControl control="date" formik={formik} type="date" 
                                                label="End Date" name="endDate" 
                                                className="col-md-4 col-xs-12" 
                                                min={moment().subtract(45, 'day').format(moment.HTML5_FMT.DATE)}
                                                //max={moment().subtract(0, 'day').format(moment.HTML5_FMT.DATE)}
                                            />
                                                    {/*
                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="Section" name="section"  
                                                            options={masters['section']} multiple={false} 
                                                        />
                                                    </div>
                                                    */}                                        
                                            <div className="col-md-3 col-xs-12 p-2" >
                                            {searchingInProgress ? 
                                                <CircularProgress/> 
                                                :
                                                <button className="btn btn-outline-primary btn-lg" type="submit">Search</button>
                                            }
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DateSelect