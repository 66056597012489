import React from 'react'
import QRCode from 'react-qr-code';

function GatepassQR({gatepass, style}) {
    return (
        <>
            {/*JSON.stringify([gatepass.id,gatepass.visitor.id])*/}
        <QRCode
            size={256}
            style={style}
            value={ JSON.stringify([gatepass.id,gatepass.visitor.id]) }
            /*
            value=  {JSON.stringify({
                g:gatepass.id,
                v:gatepass.visitor.id,
            })}
            */
            fgColor="#000000"
            viewBox={`0 0 256 256`}
        />
        </>
    )
}

export default GatepassQR


/*

{JSON.stringify({
                gatepassId:gatepass.id,
                toMeet:gatepass.attendantFirstName+" "+gatepass.attendantLastName,
                accessLocations:gatepass.accessLocationList,
                visitorName:gatepass.visitor.visitorName,
                visitorPhoto:gatepass.visitor.photo,
                startDate:gatepass.startDate,
                endDate:gatepass.endDate,
                gatepassAppId:"as1289787gh"
        })}

*/