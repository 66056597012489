import React,{useRef} from 'react'
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import LabelValue from './LabelValue';
import { Print } from '@mui/icons-material';
import GatepassQR from './GatepassQR';

function A4GatePass({gatepass,masters,getMasterLabel,businessInfo,authUser}) {
    

    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        pageStyle:"@page { size: 8.3in 11.7in; margin: 5mm 10mm; padding:0 }, @media print { @page {  size:8.3in 11.7in; margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }",
        //pageStyle:"@page { size: 3in 6in landscape; margin: 0; padding:0 }, @media print { @page {  size:3in 6in landscape; margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }",
        //pageStyle:"@page { size: 8.26in 5.82in; margin: 0; padding:0 }, @media print { @page {  size:8.26in 5.82in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }",
        //pageStyle:"@media print { html, body { height: 2.5in; /* Use 100% here to support printing more than a single page*/ margin: 0 !important;padding: 0 !important;overflow: hidden;}}; @page { size: 2.5in 2.5in; margin: 0; }"
        //onAfterPrint:()=>{navigate("/kiosk/dashboard")}
    });


    return (
            <>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        <button className='btn btn-outline-primary float-end' onClick={handlePrint}><Print/> Print</button>
                    </div>
                </div>
                <div className='border rounded p-3 mt-3' style={{width:"8.3"}}>
                    <div className='row mt-3' ref={printComponentRef}>
                        <div className='col-4 text-center my-2'>
                            {businessInfo.logo !== null ?
                                <img src={businessInfo.logo} style={{maxHeight:30, width:"auto"}} />
                                :
                                <h3>{businessInfo.title}</h3>
                            }
                            <div>Visitor Pass</div>
                        </div>
                        <div className='col-8 '>
                            <div className='float-end me-2'>Valid Duration : 
                                <strong> {moment(gatepass.startDate).format("lll")}</strong> to <strong>{moment(gatepass.endDate).format("lll")} </strong>
                            </div>
                            <div className='float-end me-2'>
                                Printed on {moment().format("lll")}
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='text-center'>
                                <img className='img-fluid img-thumbnail' src={gatepass.visitor.photo === null ? "/images/blank-profile.png"  :gatepass.visitor.photo } style={{width:"2.5in", height:"auto"}} />
                            </div>
                            <div className='text-center mt-3'>
                                <GatepassQR gatepass={gatepass} style={{width:"2in", height:"auto"}} />
                            </div>
                        </div>
                        <div className='col-8'>
                            <div className='row'>
                                <div className='col-12'><h5>Visitor Details</h5></div>
                                <div className='col-6'>
                                    <LabelValue label={"Reg No : "} value={<span className='barcode'>*G-{gatepass.id}*</span>} />
                                    <LabelValue label={"Name"} value={gatepass.visitor.visitorName} />
                                    <LabelValue label={"Gender"} value={gatepass.gender === 1 ? "Male" : "Female" } />
                                </div>
                                <div className='col-6'>
                                    <LabelValue label={"Visitor Id : "} value={<span className='barcode'>*V-{gatepass.visitorId}*</span>} />
                                    <LabelValue label={"Phone No"} value={gatepass.visitor.phone} />
                                    <LabelValue label={"Email"} value={gatepass.visitor.email} />
                                </div>
                                <div className='col-12'>
                                    <p><span className='fw-bold'>Address : </span>{gatepass.visitor.visitorAddress}</p>
                                </div>
                                <div className='col-12'><h5 className=''>To Meet</h5></div>
                                <div className='col-4'>
                                    <LabelValue label={"Officer Name"} value={gatepass.attendantFirstName} />
                                    {masters && <LabelValue label={"Purpose"} value={ getMasterLabel("visit-purpose", gatepass.purpose)} />}
                                </div>
                                <div className='col-8'>
                                    <div className='float-end'>
                                        <h5>Generated By</h5>
                                        <div>{authUser.firstName} {authUser.lastName}</div>
                                        <div>{businessInfo.title}</div>
                                    </div>
                                </div>
                                {gatepass.remark !== null && gatepass.remark !== "" &&
                                    <div className='col-12'>
                                        <LabelValue label={"Remark :"} value={gatepass.remark} />
                                    </div>
                                }
                                <div className='col-4 mt-5'>
                                    <LabelValue label={"Signature, Officer Visited"} value={""} />
                                </div>
                                <div className='col-8 mt-5'>
                                    <div className='float-end'>
                                        <span className='fw-bold'>Allowed Location : </span>
                                        {gatepass.accessLocationList.map(location=>
                                            <span className="me-1">{location.title},</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mt-3'>
                          <hr/>
                          <h3 className='text-center'>Guidelines for Visitors</h3>
                          <ol className='border rounded p-5'>
                            <li>Please keep the gatepass with you while roaming inside the office Campus</li>
                            <li>Smoking is prohibited in our location and on our premises, except in areas where smoking is indicated as permitted.</li>
                            <li>Due to the heavy traffic at our location, it is not allowed to make calls or use your cell phone in any other way while walking on our premises and in our warehouse.</li>
                            <li>Taking pictures or video and sound recordings are strictly prohibited in the entire campus area.</li>
                            <li>Mobile phone use is only allowed outside the campus buildings and with permission of the Wieland guides.</li>
                          </ol>
                      </div>
                    </div>
                </div>    
            </>
    )
}

export default A4GatePass