import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import dayjs from 'dayjs';

function GatepassValidity({startDate,endDate,setStartDate,setEndDate,remark,setRemark}) {
    
    useEffect(()=>{
        setStartDate(dayjs());
    },[])

    useEffect(()=>{
        setEndDate(null)
    },[startDate])

    return (
    <div className='row'>
        
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='col-md-6 mb-3'>
                <div className='mb-3'>
                    {
                        /*
                        <button className='btn btn-primary m-2' onClick={()=>{setStartDate(dayjs())} }  >Today</button>
                        <button className='btn btn-primary m-2' onClick={()=>{setStartDate(dayjs().add(1,"day")); setEndDate(null) }}  >Tomorrow</button>
                        <button className='btn btn-primary m-2' onClick={()=>{setStartDate(dayjs().add(2,"day")); setEndDate(null) }}  >Day After Tomorrow</button>
                        */
                    }
                </div>
                <div>
                    <DateTimePicker 
                        label="Valid From"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                    />
                </div>
            </div>
            {startDate &&
            <div className='col-md-6 mb-3'>
                <h3>Valid for </h3>
                <div className='mb-3'>
                    <button className='btn btn-primary m-2' onClick={()=>setEndDate(dayjs(startDate).add(2,"hour"))}  >2 Hours</button>
                    <button className='btn btn-primary m-2' onClick={()=>setEndDate(dayjs(startDate).add(4,"hour"))}  >4 Hours</button>
                    <button className='btn btn-primary m-2' onClick={()=>setEndDate(dayjs(startDate).add(6,"hour"))}  >6 Hours</button>
                    <button className='btn btn-primary m-2' onClick={()=>setEndDate(dayjs(startDate).add(12,"hour"))}  >12 Hours</button>
                    <button className='btn btn-primary m-2' onClick={()=>setEndDate(dayjs(startDate).add(24,"hour"))}  >24 Hours</button>
                    <button className='btn btn-primary m-2' onClick={()=>setEndDate(dayjs(startDate).add(2,"day"))}  >2 Days</button>
                </div>
                <div>
                    <DateTimePicker 
                        label="Valid Till"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                    />
                </div>
            </div>
            }
            {startDate && endDate &&
                <div className='col-md-6 mb-3'>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control"  placeholder=""
                            value={remark}
                            onChange={(e)=>setRemark(e.target.value)}
                        />
                        <label>Remark</label>
                    </div>
                </div>

            }
        </LocalizationProvider>
    </div>
    )
}

export default GatepassValidity