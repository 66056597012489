import React, { useEffect, useState } from 'react'
import locationPathServices from '../locationPathServices'
import { ArrowRight, Edit, Login } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import locactionManagerServices from '../locactionManagerServices';
import LocationPath from './LocationPath';

function LocationSelector({locationId,callback}) {
    const [dialogStatus,setDialogStatus] = useState(false);
    const [parentId,setParentId] = useState(0);
    const [locationPath,setLocationPath] = useState([]);
    const [loading,setLoadingStatus] = useState(false);

    const [locationList,setLocationList] = useState(null);

    const openLocationSelector = () =>{
        setDialogStatus(true);
        setParentId(0);
    }

    const getLocationList = () =>{
        setLoadingStatus(true);
        locactionManagerServices.getByParentId(parentId).then(res=>{
            setLocationList(res.data);
        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false)
        })
    }

    useEffect(()=>{
        getLocationList();
    },[parentId])

    const handleRootClick = () =>{
        setLocationPath([]);
        setParentId(0)
    }

    const handleLocationClick = (location) =>{
        let _pathList = [...locationPath,location];
        setLocationPath(_pathList);
        setParentId(location.id)
    }

    const handlePathClick = (index) =>{
        //console.log(index)
        let _pathList = [...locationPath]
        //console.log(_pathList);
        _pathList.length = index+1;
        //console.log(_pathList);
        setLocationPath(_pathList);
        setParentId(_pathList[index].id);
    }

    const onLocationSelect = (location) =>{
        callback(location,locationPath);
        setDialogStatus(false);
    }

    return (
        <>
            

            {locationId == 0 ?
                <button className='btn btn-outline-primary' onClick={openLocationSelector} >Select a Location</button>
                :
                <button className='btn btn-warning ms-3' onClick={openLocationSelector}> <Edit/> Change</button>
            }

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>Select a Location</DialogTitle>

                <div className='card'>
                    <div className='card-header'>
                        
                            <div className="d-flex justify-content-start">
                                <div className="me-2">
                                    <button type="button" className='btn btn-primary' onClick={handleRootClick} >Root</button>
                                    <span className='ms-2'><ArrowRight/></span>
                                </div>
                                {locationPath?.map((path,i) =>
                                    <div key={i} className="me-2">
                                        {i < locationPath.length-1 ?
                                            <>
                                                <button type="button" onClick={()=>handlePathClick(i)}  className='btn btn-outline-primary'>{path.title}</button>
                                                <span className='ms-2'><ArrowRight/></span>
                                            </>
                                            :
                                            <button type="button" className='btn btn-light'>{path.title}</button>
                                        }
                                    </div>
                                )}
                            </div>
                        
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            {locationList?.length == 0 &&
                                <p className='text-center'>No location inside <ArrowRight/> <span className='border rounded p-2 m-2'>Root</span> {locationPath.map(location=><span className='border rounded p-2 m-2'> <ArrowRight/> {location.title} </span>)}</p>
                            }
                            {locationList?.length > 0 && locationList.map(location=>
                                //<Chip icon={ <Edit/> } className='mx-2' key={location.id} label={location.title} onClick={()=>handleLocationClick(location)} />
                                <div key={location.id} className='col-md-3' >
                                    <div  className='card mb-2 '>
                                        <div className='card-body' >
                                            {location.title}
                                            <button className='btn btn-dark float-end' type={"button"} onClick={()=>handleLocationClick(location)} > Enter <Login /> </button>
                                        </div>
                                        <div className='card-footer text-center'>
                                            <button className='btn btn-outline-primary' onClick={()=>onLocationSelect(location)} >Select "{location.title}"</button>
                                        </div>
                                    </div>
                                </div>
                            )}                            
                        </div>
                        
                    </div>
                </div>

                <DialogContent></DialogContent>
            </Dialog>
            
        </>
    )
}

export default LocationSelector