import React from 'react'

const LabelValue = ({label, value}) =>{
    return(
        <div className='d-flex justify-content-between'>
            <div className='me-1'><span className='fw-bold'>{label}</span></div>
            <div className=''>{value}</div>
        </div>
    )
}

export default LabelValue
