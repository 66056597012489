import React, { useCallback, useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import api from '../../api/api'


import { useAlert } from '../../context/AlertContext';
import { useAuth } from '../../context/AuthContext';

import Webcam from "react-webcam";
import CameraImage from './CameraImage';


const LocalImageInline = ({ label, name, value, editId, updateInline, path, fileId, onSuccess,thumb=true}) => {
                        
  const {AppAlert} = useAlert();
  const {authUser} = useAuth()
  const userId=  authUser.id;

  const [dialogStatus, setDialogStatus] = useState(false);
  const [uploadInProgress, setUploadProgress] = useState(null);
  const [inputValue,setInputValue] = useState(value);
  const [imagePath,setImagePath] = useState(null);
  const [uploaded ,setUploadedStatus] =  useState(false);

  
  const isImage = (file) =>{
    if(file.type.match('image.*')){
        return true;
    }
    return false;
  }
  

  const uploadToServer = (formData) =>{
    setUploadedStatus(false);
    api.post("upload/image/local",formData,{
        onUploadProgress: (progressEvent) => { 
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            //console.log(percent);
            setUploadProgress(percent)
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    }).then( (res)=>{
          setUploadProgress(null)
          setUploadedStatus(true);
          setInputValue(res.data.filePath);
          setTimeout(()=>{
            setImagePath(res.data.filePath);  
          },100)
        console.log("here in upload aws success",res);
    }).catch( (error) => {
        setUploadProgress(null)
        console.log(error);
    })
  }

  const uploadFile = (e) =>{
    if(!isImage(e.target.files[0])){
        AppAlert("Invalid File Type","error");
        return false;
    }
    let formData = new FormData();
    formData.append('path',path);
    formData.append('editId',editId);
    formData.append('fileId',fileId);
    formData.append('tmpFile', e.target.files[0]);
    uploadToServer(formData);
  }



  const addNew = ()=>{
      setDialogStatus(true)
  }

  const  updateImageField = () =>{
    let data = {};
    data[name] = inputValue;
    data['id'] = editId;
    let payload = {
            data:data,
            type:"awsimage",
            editId:editId,
            userId:userId,
        }
    updateInline(payload).then(res=>{
        AppAlert("Updated Successfully")
        onSuccess();
        setDialogStatus(false)
    }).catch(error => {
        AppAlert(error.msg);
    })
  }

  useEffect(() =>{
    if(inputValue){
      setImagePath(inputValue);
    } else {
      setImagePath("/images/blank-profile.png")
    }
  },[inputValue])
  


  const [imageDialogStatus,setImageDialogStatus] = useState(false);
  

  /*****   Camera Work In Progress */
  const  DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }
  const uploadCameraImage = (image) => {
    let formData = new FormData();
    formData.append('path',path);
    formData.append('editId',editId);
    formData.append('fileId',fileId);
    formData.append('tmpFile',DataURIToBlob(image));
    uploadToServer(formData);
  }


  return (
    <>
          {thumb &&
          <div className="v2-img-container" style={{width:"100%"}}>
              <img className="v2-image"  src={imagePath} alt={label} />
              <div className="v2-middle">
                <button type="button" className="btn btn-danger btn-sm m-1" onClick={addNew}>Change</button>
                <button type="button" className="btn btn-warning btn-sm m-1" onClick={()=>setImageDialogStatus(true)}>View</button>
            </div>
          </div>
          }
          {!thumb &&
            <>
            {imagePath!=="/images/blank-profile.png" && <a className="btn btn-primary btn-sm me-2"  href={imagePath} target="_blank" > View </a> }
            {imagePath!=="/images/blank-profile.png" && <button type="button" className="btn btn-danger btn-sm m-1" onClick={addNew}>Update</button> }
            {imagePath==="/images/blank-profile.png" && <button type="button" className="btn btn-danger btn-sm m-1" onClick={addNew}>Add {label}</button> }
            </>
          }
      

          <Dialog
              open={imageDialogStatus}
              keepMounted
              onClose={()=>setImageDialogStatus(false)}
              fullWidth={true}
              maxWidth="lg"
              aria-describedby="alert-dialog-slide-description"
          >
            <img  src={imagePath} alt={label} />
          </Dialog>

          <Dialog
              open={dialogStatus}
              keepMounted
              onClose={()=>setDialogStatus(false)}
              fullWidth={true}
              maxWidth="md"
              aria-describedby="alert-dialog-slide-description"
          >

          <DialogTitle><span className='text-primary'>{label}</span></DialogTitle>
              <DialogContent>

                <div className="row">
                  <div className='col-md-6'>
                    { uploadInProgress !== null && uploadInProgress < 100 && <LinearProgress variant="determinate" value={uploadInProgress} /> }
                    { uploadInProgress === null && <img className="img-thumbnail v2-thumb" src={imagePath} alt={label} />}
                  </div>
                  <div className="col-md-6 mt-5 text-center">
                    <span className=''><CameraImage uploadCameraImage={uploadCameraImage} /></span>
                    <span className=''> 
                      <input type="file"
                        className='form-control mt-2'
                        onChange={ (e)=>{ uploadFile(e) } } 
                        accept="image/png, image/gif, image/jpeg"  
                      /> 
                    </span>
                    { /*<label htmlFor="upload" className="btn btn-primary  float-end">Choose File</label> */ }
                  </div>
                  
                  <div className='col-md-12 mt-2'>
                    {uploaded  && <button type="button" className="btn btn-primary btn-lg float-end" onClick={updateImageField} >Update {label}</button> }
                    <button type="button" className="btn btn-outline-primary btn-lg float-start" onClick={()=>setDialogStatus(false)} >Cancel</button>
                  </div>
                </div>

                
                
              </DialogContent>
              
                
              
          </Dialog>
    </>
  )
}


export default LocalImageInline;