import React, { useEffect, useState } from 'react'
import { useAlert } from '../../../../context/AlertContext'
import AppLayout from '../../../../layout/components/AppLayout'
import { Chip } from '@mui/material'
import locactionManagerServices from '../locactionManagerServices';
import TextInline from '../../../../components/InlineEdit/TextInline';
import { ArrowRight, Login } from '@mui/icons-material';

function LocationManagerPage() {
    const {AppAlert} = useAlert();
    
    const [title,setTitle] = useState("");
    const [parentId,setParentId] = useState(0);
    const [locationPath,setLocationPath] = useState([]);
    const [loading,setLoadingStatus] = useState(false);

    const [locationList,setLocationList] = useState(null);

    const getLocationList = () =>{
        setLoadingStatus(true);
        locactionManagerServices.getByParentId(parentId).then(res=>{
            setLocationList(res.data);
        }).catch(error=>{
            AppAlert("Something Went Wrong","error");
        }).finally(()=>{
            setLoadingStatus(false)
        })
    }

    useEffect(()=>{
        getLocationList();
    },[parentId])

    const addLocation = () =>{
        
        let _locationPath = locationPath.map(location=>location.id);

        let payload = {
            title:title,
            parentId:parentId,
            locationPath:_locationPath.toString(),
        }
        console.log(payload)
        locactionManagerServices.save(payload).then(res=>{
            AppAlert("New Location Created");
            getLocationList();
            setTitle("");
        }).catch(error=>{
            console.log(error);
        })
    }


    

    const handleRootClick = () =>{
        setLocationPath([]);
        setParentId(0)
    }

    const handleLocationClick = (location) =>{
        let _pathList = [...locationPath,location];
        setLocationPath(_pathList);
        setParentId(location.id)
    }

    const handlePathClick = (index) =>{
        //console.log(index)
        let _pathList = [...locationPath]
        //console.log(_pathList);
        _pathList.length = index+1;
        //console.log(_pathList);
        setLocationPath(_pathList);
        setParentId(_pathList[index].id);
    }

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='card'>
                    <div className='card-header'>
                        
                            <div class="d-flex justify-content-start">
                                <div className="me-2">
                                    <button type="button" className='btn btn-primary' onClick={handleRootClick} >Root</button>
                                    <span className='ms-2'><ArrowRight/></span>
                                </div>
                                {locationPath?.map((path,i) =>
                                    <div key={i} className="me-2">
                                        {i < locationPath.length-1 ?
                                            <>
                                                <button type="button" onClick={()=>handlePathClick(i)}  className='btn btn-outline-primary'>{path.title}</button>
                                                <span className='ms-2'><ArrowRight/></span>
                                            </>
                                            :
                                            <button type="button" className='btn btn-light'>{path.title}</button>
                                        }
                                    </div>
                                )}
                            </div>
                        
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            {locationList?.length == 0 &&
                                <p className='text-center'>No Location Exist at <span className='border rounded p-2 m-2'>Root</span>{locationPath.map(location=><span className='border rounded p-2 m-2'>{location.title} </span>)}</p>
                            }
                            {locationList?.length > 0 && locationList.map(location=>
                                //<Chip icon={ <Edit/> } className='mx-2' key={location.id} label={location.title} onClick={()=>handleLocationClick(location)} />
                                <div className='col-md-3' >
                                    <div key={location.id} className='card mb-2 bg-warning'>
                                        <div className='card-body' >
                                            <TextInline name="title" value={location.title} editId={location.id} updateInline={locactionManagerServices.updateInline} />
                                            <button className='btn btn-dark float-end' type={"button"} onClick={()=>handleLocationClick(location)} > Enter <Login/> </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            
                            <div className='col-md-3'>
                                <div className='card'>
                                    <div className='card-body'>
                                        {locationPath.length > 0 &&
                                            <p>Add New location at <span className='fw-bold'>" {locationPath[locationPath.length-1].title} "</span></p>
                                        }
                                        {locationPath.length === 0 &&
                                            <p>Create New Location</p>
                                        }
                                        <div className="input-group my-3" style={{maxWidth:400}}>
                                            <input type="text" className="form-control" placeholder="Location Name eg : Room No. / Block No."
                                                value={title} onChange={(e)=>setTitle(e.target.value)}
                                            />
                                            <button className="btn btn-outline-primary" type="button" onClick={addLocation} >Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </AppLayout>
    )
}

export default LocationManagerPage