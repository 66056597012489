import React,{useRef} from 'react'
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import LabelValue from './LabelValue';
import { Print } from '@mui/icons-material';
import GatepassQR from './GatepassQR';

function Label3x3GatePass({gatepass,masters,getMasterLabel,businessInfo,authUser}) {
    

    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        pageStyle:"@page { size: 3in 3in; margin: 0mm 0mm; padding:0 }, @media print { @page {  size:3in 6in; margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }",
        //pageStyle:"@page { size: 3in 6in landscape; margin: 0; padding:0 }, @media print { @page {  size:3in 6in landscape; margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }",
        //pageStyle:"@page { size: 8.26in 5.82in; margin: 0; padding:0 }, @media print { @page {  size:8.26in 5.82in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }",
        //pageStyle:"@media print { html, body { height: 2.5in; /* Use 100% here to support printing more than a single page*/ margin: 0 !important;padding: 0 !important;overflow: hidden;}}; @page { size: 2.5in 2.5in; margin: 0; }"
        //onAfterPrint:()=>{navigate("/kiosk/dashboard")}
    });


    return (
        <center>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <button className='btn btn-outline-primary float-end' onClick={handlePrint}><Print/> Print</button>
                </div>
            </div>
            <div style={{width:"3in"}} className='border p-2 rounded mt-3'>
                    <div className='row' ref={printComponentRef}>
                        <div className='col-12 text-center mt-1'>
                            {businessInfo.logo !== null ?
                                <img src={businessInfo.logo} style={{maxHeight:30, width:"auto"}} />
                                :
                                <h3>{businessInfo.title}</h3>
                            }
                            <div className='m-0' style={{fontSize:12}}>Visitor Pass</div>
                        </div>
                        <div className='col-12 text-center'>
                            <GatepassQR gatepass={gatepass}  style={{ height: "auto", width: "1.5in" }} />
                        </div>
                        <div className='col-12'>
                            <div className='mx-3'>
                            <LabelValue label={"Name"} value={gatepass.visitor.visitorName} />
                            <LabelValue label={"Phone No"} value={gatepass.visitor.phone} />
                            <LabelValue label={"To Meet"} value={gatepass.attendantFirstName} />
                            </div>
                        </div>
                        <div className='col-12 text-center'>
                            <div className='' style={{fontSize:8}}>Valid from : 
                                <strong> {moment(gatepass.startDate).format("lll")}</strong> to <strong>{moment(gatepass.endDate).format("lll")} </strong>
                            </div>
                        </div>
                    </div>
            </div>
        </center>
    )
}

export default Label3x3GatePass