import React from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { Link } from 'react-router-dom'
import { DocumentScanner, QrCode } from '@mui/icons-material'

function AdminLandingPage() {
  return (
    <AppLayout>
      <div className='container'>
        <div className='row mt-5 text-center'>
          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/gatepass/list"} className='btn text-white'   >
                  <h3><QrCode style={{fontSize:60}} /></h3>
                  <p>Gatepass List</p>
                </Link>
              </div>
          </div>
          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/gatepass/generate"} className='btn text-white'   >
                  <h3><DocumentScanner style={{fontSize:60}} /></h3>
                  <p>Genarate Gatepass</p>
                </Link>
              </div>
          </div>
          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/gatepass/scan"} className='btn text-white'   >
                  <h3><DocumentScanner style={{fontSize:60}} /></h3>
                  <p>Scan Gatepass</p>
                </Link>
              </div>
          </div>          
        </div>
      </div>
    </AppLayout>  
  )
}

export default AdminLandingPage