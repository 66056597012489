import React, { useEffect, useState, useRef } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import userServices from '../userServices';
import { useAlert } from '../../../../context/AlertContext';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom';
import UserForm from '../components/UserForm';

const RenderLink = (params) =>{
    return (
        <Link to={`/user/details/${params.data.id}`}>{params.data.firstName+" "+params.data.lastName}</Link>
    )
}

function UserListPage() {
    
    const {AppAlert} = useAlert();
    const gridRef = useRef();
    const [userList,setUserList] = useState(null);

    const getUserList = () =>{
        userServices.getList().then(res=>{
            setUserList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    });

    const [columnDefs,setColumnDef] = useState();
    const prepairColumnDef = () => {
        let _columnDefs = [
            {field:"id",headerName: "ID"},
            {headerName:"Name", width:"200", valueGetter: params => params.data.firstName+" "+params.data.lastName,cellRenderer: RenderLink},
            {field:"email",headerName:"Email"},
            {field:"phone",headerName:"Phone"},
            {field:"roleTitle",headerName:"Role"},
        ];
        setColumnDef(_columnDefs);
    }

    
    /* Prepair User Role DD*/
    const [masters,setMasters] = useState(null);
    const getUserRoleDD = () => {
        userServices.getUserRoleDDList().then(res=>{           
            setMasters({"user-role":res.data})
            prepairColumnDef();
        })
    }

    useEffect(()=>{
        getUserRoleDD();
    },[]);
    /* Prepair User Role DD*/

    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv();
    }
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <span className='float-end'><UserForm masters={masters} refreshList={getUserList} /></span>
                    </div>
                    <div className='col-md-12'>
                        <div className="ag-theme-alpine" style={{height: 400}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={userList}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                animateRows={true}
                                onGridReady={getUserList}
                                >
                            </AgGridReact>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
        
    )
}

export default UserListPage