import React,{useState,useEffect} from 'react'
import useStaffList from '../../../hooks/useStaffList';
import AppLayout from '../../../layout/components/AppLayout'
import SelectVisitor from '../components/SelectVisitor';
import AppSelect from '../../../components/AppInput/AppSelect';
import useSystemMasterList from '../../../hooks/useSystemMasterList';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import GatepassValidity from '../components/GatepassValidity';
import gatepassServices from '../gatepassServices';
import { useAlert } from '../../../context/AlertContext'
import { useNavigate } from 'react-router-dom';
import LocationPath from '../../admin/LocationManager/components/LocationPath';
import LocationSelector from '../../admin/LocationManager/components/LocationSelector';

function GenerateGatepassPage() {
	const {AppAlert} = useAlert();
	const [visitor,setVisitor] = useState(null);
	const [attendant,setAttendant] = useState(null);
	const [purpose,setPurpose] = useState(null);
	const [startDate,setStartDate] = useState();
    const [endDate,setEndDate] = useState();
	const [remark,setRemark]= useState("");

	const staffList = useStaffList();
	const [masters,getMasterLabel] =  useSystemMasterList(["visit-purpose"])
	const [activeStep,setActiveStep] = useState(0)

	const [saving,setSavingStatus] = useState(false)
	const navigate =useNavigate();
	const generateGatepass = () =>{
		let _accessLocationList = accessLocationList.map(accessLocation=>accessLocation.id);
		let payload ={
			attendantId : attendant.value,
			visitorId: visitor.id,
			accessLocations: JSON.stringify(_accessLocationList),
			purpose:purpose.value,
			startDate:startDate.format("YYYY-MM-DD HH:mm:ss"),
			endDate:endDate.format("YYYY-MM-DD HH:mm:ss"),
		}
		console.log(payload)
		
		if(remark!=="") payload.remark = remark
		setSavingStatus(true)
		gatepassServices.save(payload).then(res=>{
			console.log(res)
			AppAlert("Gatepass Generated");
			navigate("/gatepass/details/"+res.id)
		}).catch(error=>{
			AppAlert(error.msg,"error");
		}).finally(()=>{
			setSavingStatus(false)
		})
		

	}



	/* -------------Access Location Work Start Here-------------	*/

	const [locationId,setLocationId] = useState(null)
	const [accessLocationList,setAccessLocationList] = useState([]);
	/* 
		As Location  Id state change LocationPath component and LocationSelector componet 
		will rerender and update access location list
	*/

	/*
		Set Location id as attendent state change 
		So LocationPath compenent will rerender and diaplay Access location List
	*/
	useEffect(()=>{
		if(attendant!==null){
			setLocationId(attendant.locationId);
		}
	},[attendant])

	/*
		Call back function to update accesslocation List
	*/
	const updateAccessLocationList = (path) =>{
		setAccessLocationList(path)
	}

	/*
		Call back function to to update LocationId 
		As LocationId state change Location Path component will rerender and update access location list
	*/
	
	const updateLocation = (location) =>{
		setLocationId(location.id);
	}

	/* -------------Access Location Work End Here-------------	*/

    return (
      	<AppLayout>
			<div className='container'>
				
				<Stepper activeStep={activeStep} orientation='vertical'>
					<Step>
						<StepLabel >To Meet {attendant?.label}</StepLabel>
						<StepContent>
							<div className='row'>

								<div className='col-md-12 mb-3'>
									{masters &&
										<AppSelect
											options={[{value:null,label:"Select"}, ...masters['visit-purpose']]}
											value={purpose}
											setValue={setPurpose}
											label="Visit Purpose" 
											optionLabel = "label"
										/>
									}
								</div>

								<div className='col-md-6 mb-3'>
									{staffList &&
										<AppSelect
											options={[{value:null,label:"Select"}, ...staffList]}
											value={attendant}
											setValue={setAttendant}
											label="To Meet" 
											optionLabel = "label"
										/>
									}
								</div>

								<div className='col-md-6 mb-3'>
									{attendant &&
										<>
											<LocationPath locationId={locationId} callback={updateAccessLocationList} />
											<div className='pt-2'><LocationSelector locationId={locationId} callback={updateLocation} /></div>
											{/*JSON.stringify(accessLocationList) */}
										</>
									}
								</div>
								<div className='col-md-12 mb-3'>
									<button className='btn btn-primary' onClick={()=>setActiveStep(1)}  disabled={!purpose || !attendant || accessLocationList.length === 0 }>Next</button>
								</div>
							</div>
						</StepContent>
					</Step>
					<Step>
						<StepLabel > {visitor && <img className='rounded' src={visitor?.photo} style={{height:50,width:50}} /> } {visitor!== null ? visitor.visitorName : "Visitor"}</StepLabel>
						<StepContent>
							<SelectVisitor setVisitor={setVisitor} next={()=> setActiveStep(2)} />
							<button className='btn btn-outline-primary my-2' onClick={()=>setActiveStep(0)} >Back</button>
						</StepContent>
					</Step>

					<Step>
						<StepLabel >Validation Details</StepLabel>
						<StepContent>
							<GatepassValidity 
								startDate={startDate}
								endDate={endDate}
								setStartDate={setStartDate}
								setEndDate={setEndDate}
								remark={remark}
								setRemark={setRemark}
							/>
							{startDate && endDate &&
								<div className='row mb-3'>
									<button className='btn btn-lg btn-primary' onClick={generateGatepass} >Generate</button>
								</div>
							}
							<button className='btn btn-outline-primary my-2' onClick={()=>setActiveStep(1)} >Back</button>
						</StepContent>
					</Step>

				</Stepper>
					
				{/*
				<SelectVisitor setVisitorId={setVisitorId} />
				*/}
			</div>
			
		</AppLayout>
  	)
}

export default GenerateGatepassPage