import React,{useEffect, useState} from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import LabelValue from '../components/PrintStyles/LabelValue';
import moment from 'moment';
import gatepassServices from '../gatepassServices';
import SearchByQrCode from '../components/SearchByQrCode';

function GatepassScanCheckin() {

    const [scannedJson,setScannedJson] = useState(null)
    const [gatepass,setGatepass]= useState(null)

    useEffect(()=>{
        if(scannedJson !== null){
            gatepassServices.getById(scannedJson[0]).then(res=>{
                setGatepass(res.data);
            })
        }
    },[scannedJson])

    return (
        <AppLayout>
            <div className='container'>
                <div className='row'>
                    <div className='cold-md-12 text-center'>
                        <SearchByQrCode setScannedJson={setScannedJson } />
                    </div>
                </div>
                <center>
                <div style={{width:"3.5in",height:"auto"}}>
                { gatepass &&
                    <div className='card mt-3'>
                        <img src={gatepass.visitor.photo === null ? "/images/blank-profile.png"  :gatepass.visitor.photo } className="card-img-top" alt={gatepass.visitorName} />
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                   <LabelValue label={"Name"} value={gatepass.visitor.visitorName} />
                                </div>
                                <div className='col-md-12'>
                                    <span className='fw-bold'>Allowed Location : </span>
                                    {gatepass.accessLocationList.map(location=>
                                        <span className="me-1">{location.title},</span>
                                    )}
                                </div>
                                <div className='col-12 text-center'>
                                    <div className=''>Valid from : 
                                        <strong> {moment(gatepass.startDate).format("lll")}</strong> to <strong>{moment(gatepass.endDate).format("lll")} </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <button className='btn btn-success'>Checkin</button>
                        </div>
                    </div>
                }
                </div>
                </center>
            </div>
            
        </AppLayout>
    )
}

export default GatepassScanCheckin