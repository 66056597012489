import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useAlert } from '../../../context/AlertContext'
import { Formik, Form } from 'formik'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { LinearProgress } from '@mui/material';
import * as Yup from 'yup'
import useSystemMasterList from '../../../hooks/useSystemMasterList';
import visitorServices from '../visitorServices';



function VisitorForm({setVisitorIdInput}) {
    
    const [dialogStatus,setDialogStatus] = useState(false)
    const {AppAlert} = useAlert();
    const [savingInProgress,setSavingStatus] = useState(false);
	const [masters,getMasterLabel] =  useSystemMasterList(['gender',"visit-purpose","id-type"])

    const initialValues = {
        visitorName:"",
        gender:"",
        visitorAddress:"",
        email:"",
        phone:"",
		idType:"",
        idProff:"",
    }

    const validationRule = Yup.object({
        visitorName:Yup.string().required("Required"),
        gender:Yup.string().required("Required"),
        visitorAddress:Yup.string().required("Required"),
		phone:Yup.string().required("Required").matches(/^[0-9]+$/, "Invalid Phone Number").min(10, 'Invalid Phone Number').max(10, 'Invalid Phone Number'),
		idType:Yup.string().required("Required"),
		idProff:Yup.string().when(['idType'],(idType,schema,val)=>{
            //console.log("idType is",idType[0]);
            if(idType[0] !== undefined && idType[0] !== "1"){
                return Yup.string().required("Required");
            }
        }),
    });

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        visitorServices.save(values).then(res=>{
          console.log(res);
          setVisitorIdInput(res.id)
          setDialogStatus(false);
          //navigate("/gatepass/details/"+res.id);
        }).catch(error=>{
          AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false)
        })
    }

    return (
        <>
            <button className='btn btn-primary mx-2' onClick={()=>setDialogStatus(true)} >Add New</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>Visito Form</DialogTitle>
                <DialogContent>


                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationRule}
                        onSubmit={onSubmit}
                        enableReinitialize validateOnMount
                    >
                    {
                        (formik) => {
                            console.log(formik);
                            return (
                                <Form>
								
								
								
									
											<div className='row mt-3 mx-3'>
												<div className='col-md-6 mb-3'>
													<FormikControl control="input" formik={formik} type="text" 
														label="Visitor Name" name="visitorName" 
													/>
												</div>
												
												{masters &&
													<div  className='col-md-3 mb-3'>
														<FormikControl control="autoselect" formik={formik} 
															label="Gender" name="gender"  
															options={masters.gender} multiple={false} 
															
														/>
													</div>
												}
												
												<div className='col-md-3 mb-3'>
													<FormikControl control="input" formik={formik} type="text" 
														label="Phone" name="phone" 
													/>
												</div>

                                                <div className='col-md-12 mt-3'><h4>Contact Details</h4></div>
                                                
												<div className='col-md-4 mb-3'>
													<FormikControl control="input" formik={formik} type="text" 
														label="Email" name="email" 
														
													/>
												</div>
												
												<div className='col-md-8 mb-3'>
													<FormikControl control="input" formik={formik} type="text" 
														label="Address" name="visitorAddress" 
													/>
												</div>

												{masters &&
													<div  className='col-md-3 mb-3'>
														<FormikControl control="autoselect" formik={formik} 
															label="Select ID Type" name="idType"  
															options={masters['id-type']} multiple={false} 
															
														/>
													</div>
												}


												{formik.values.idType > 1 &&
													<div className='col-md-9 mb-3'>
														<FormikControl control="input" formik={formik} type="text" 
															label="Id Proof Details" name="idProff"
															
														/>
													</div>
                                                }
                                            </div>
												
											
										

									<div className="row mt-3 mx-5">
										<div className='col-md-12'>
											{savingInProgress ?
												<LinearProgress/>
												:
												<>
													<button className="btn btn-lg btn-success float-end" type="submit">Next</button>
													<button className="btn btn-lg btn-outline-primary" onClick={()=>setDialogStatus(false)} type="button">Cancel</button>
												</>
											}
										</div>
										<div className='col-md-12'>
											{ /* JSON.stringify(formik.values)  */}
										</div>
									</div>
								
                                </Form>
                            )

                        }
                    }
			</Formik>





                </DialogContent>
            </Dialog>
        </>
    )
}

export default VisitorForm