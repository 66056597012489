import React, { useEffect, useState } from 'react'
import locationPathServices from '../locationPathServices'
import { ArrowRight } from '@mui/icons-material'


function LocationPath({locationId, callback}) {

    const [path,setPath] = useState(null)
    const getPath = () =>{
        locationPathServices.getLocationPath(locationId).then(res=>{
            //console.log(res);
            setPath(res.data);
            if(callback){
                console.log("Calling Callback")
                callback(res.data);
            }
        }).catch(error=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        getPath()
    },[locationId])


    return (
        <>
        {path && path.length > 0 &&
            <>
                <div className='btn-group me-3' >
                    {path.map(pathDetails=>
                        <button key={pathDetails.id} className='btn btn-primary'><ArrowRight/> {pathDetails.title} </button>
                    )}
                </div>
            </>
        }
        </>
    )
}

export default LocationPath