import {useState,useEffect} from 'react'
import hookServices from './hookServices';

function useStaffList(masterNameList,type) {
    const [staffList,setStaffList] = useState(null);
    const getStaffList = () =>{
        hookServices.getStaffDDList().then(res=>{
            setStaffList(res.data);
        }).catch(error=>{

        })
    }
    useEffect(()=>{
        getStaffList();
    },[])
    return staffList;
}

export default useStaffList