import React from 'react'
import { Route, Routes } from 'react-router'
import AdminLandingPage from '../app/admin/dashboard/pages/AdminLandingPage'
import GenerateGatepassPage from '../app/gatepass/pages/GenerateGatepassPage'
import GatepassDetailsPage from '../app/gatepass/pages/GatepassDetailsPage'
import GatepassListPage from '../app/gatepass/pages/GatepassListPage'
import GatepassScanCheckin from '../app/gatepass/pages/GatepassScanCheckin'
import LocationManagerPage from '../app/admin/LocationManager/pages/LocationManagerPage'


function ManagerRoutes() {
  return (
    <>
      <Routes>
        {/* Admin */}
        <Route path="/" element={ <AdminLandingPage /> }></Route>
        <Route path="/gatepass/generate" element={ <GenerateGatepassPage /> }></Route>
        <Route path="/gatepass/scan" element={ <GatepassScanCheckin /> }></Route>
        <Route path="/gatepass/details/:id" element={ <GatepassDetailsPage /> }></Route>
        <Route path="/gatepass/list" element={ <GatepassListPage /> }></Route>



        <Route path="/location/manager" element={ <LocationManagerPage /> }></Route>
      </Routes>
    </>
  )
}

export default ManagerRoutes