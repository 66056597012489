import React,{useState,useEffect} from 'react'
import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material'
import visitorServices from '../visitorServices';

function SearchByPhone({setVisitorIdInput}) {
    const [dialogStatus,setDialogStatus] = useState(false)
    const [phoneInput,setPhoneInput] = useState("");
    const [loading,setLoadingStatus] = useState(false);
    const [visitorList,setVisitorList] = useState(null);
    

    const getVisitorList = () =>{
        if(phoneInput.length == 10){
            setVisitorList(null);
            setLoadingStatus(true);
            visitorServices.getByPhone(phoneInput).then(res=>{
                if(res.data.length > 0){
                    setVisitorList(res.data)
                }
            }).catch(error=>{
                
            }).finally(()=>{
                setLoadingStatus(false)
            })
        }
    }


    useEffect(()=>{
        getVisitorList()
    },[phoneInput])

    const selectVisitor =  (id) =>{
        setVisitorIdInput(id)
        setDialogStatus(false);
    }
    
    return (
        <>
            <button className='btn btn-primary mx-2' onClick={()=>setDialogStatus(true)} >Search By Phone</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" placeholder="" value={phoneInput} onChange={(e)=>setPhoneInput(e.target.value)} />
                        <label >Phone Number</label>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {loading && <div className='mt-4 text-center'> <CircularProgress /></div>}
                    {!loading && visitorList &&
                        <div className='row'>
                            {visitorList.map(visitor=>
                                <div className='col-md-4 col-sm-12 mb-2' key={visitor.id} onClick={ ()=> selectVisitor(visitor.id)}>
                                    <div className='card'>
                                        <img src={visitor.photo === null ? "/images/blank-profile.png" : visitor.photo} class="card-img-top" alt="..." style={{width:"100%",height:300}} />
                                        <div className='card-body'>
                                            <h5 class="card-title">{visitor.visitorName}</h5>
                                        </div>
                                    </div>    
                                </div>
                            )}
                        </div>
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SearchByPhone